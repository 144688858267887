import { AppVariant } from '../generated/graphql'
import FootballHQIcon from '../images/app/footballhqIcon.png'
import FootballHqScreenshots from '../images/app/footballhqScreenshots.png'
import MyHoopsIcon from '../images/app/myhoopsIcon.png'
import MyHoopsScreenshots from '../images/app/myhoopsScreenshots.png'
import NetballHqBackground from '../images/app/netballhqBackground.jpg'
import NetballHQIcon from '../images/app/netballhqIcon.png'
import NetballHqScreenshots from '../images/app/netballhqScreenshots.png'
import PlayAFLIcon from '../images/app/playaflIcon.png'
import PlayAFLScreenshots from '../images/app/playaflScreenshots.png'

export interface AppVariantConfig {
  slug: string
  name: string
  sportAlt: string
  brandColor?: string
  assets: {
    icon: string
    screenshots: string
    background?: string
  }
  urls: {
    info: string
    appleAppStore: string
    googlePlayStore: string
  }
}

export const appVariantConfigs: Partial<Record<AppVariant, AppVariantConfig>> =
  {
    [AppVariant.Afl]: {
      slug: 'playafl',
      name: 'Play AFL',
      sportAlt: 'footy',
      brandColor: '#2f52e0',
      assets: {
        icon: PlayAFLIcon,
        screenshots: PlayAFLScreenshots,
      },
      urls: {
        info: 'https://www.playhq.com/au/apps/playafl',
        appleAppStore: 'https://apps.apple.com/au/app/playafl/id6449444200',
        googlePlayStore:
          'https://play.google.com/store/apps/details?id=com.playhq.playafl',
      },
    },
    [AppVariant.Basketball]: {
      slug: 'myhoops',
      name: 'MyHoops',
      sportAlt: 'basketball',
      assets: {
        icon: MyHoopsIcon,
        screenshots: MyHoopsScreenshots,
      },
      urls: {
        info: 'https://www.playhq.com/au/apps/myhoops',
        appleAppStore: 'https://apps.apple.com/au/app/myhoops/id6449492986',
        googlePlayStore:
          'https://play.google.com/store/apps/details?id=com.playhq.myhoops',
      },
    },
    [AppVariant.Netball]: {
      slug: 'netballhq',
      name: 'NetballHQ',
      sportAlt: 'netball',
      assets: {
        icon: NetballHQIcon,
        screenshots: NetballHqScreenshots,
        background: NetballHqBackground,
      },
      urls: {
        info: 'https://www.playhq.com/au/apps/netballhq',
        appleAppStore: 'https://apps.apple.com/au/app/netballhq/id6478189779',
        googlePlayStore:
          'https://play.google.com/store/apps/details?id=com.playhq.netballhq',
      },
    },
    [AppVariant.Football]: {
      slug: 'footballhq',
      name: 'FootballHQ',
      sportAlt: 'football',
      assets: {
        icon: FootballHQIcon,
        screenshots: FootballHqScreenshots,
      },
      urls: {
        info: 'https://www.playhq.com/au/apps/footballhq',
        appleAppStore: 'https://apps.apple.com/au/app/footballhq/id6741540147',
        googlePlayStore:
          'https://play.google.com/store/apps/details?id=com.playhq.footballhq',
      },
    },
  }
